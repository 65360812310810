import React from 'react';
// import PropTypes from 'prop-types'
import Layout from '../components/layout';

const GrmOrder = () => {
  return (
    <Layout className='flex items-center justify-center'>
      <div className='mx-auto'>
        <iframe
          width='400'
          height='500'
          frameBorder='0'
          src='https://customer.1capp.com/#/form?code=402880824e43d0d0014e43d4f2f42ace'
        ></iframe>
      </div>
    </Layout>
  );
};

export default GrmOrder;
